<template>
  <el-dialog
    class="apply-detail"
    :append-to-body="true"
    :visible="dialogShow"
    width="80%"
    :before-close="handleClose"
    center
  >
    <blockquote class="my-blockquote">申请信息</blockquote>
    <el-tabs v-model="activeName" @tab-click="handleClick" >
      <el-tab-pane label="公司信息" name="first">
        <el-form
          ref="form"
          :model="form"
          label-width="80px"
          label-position="left"
        >
          <el-row>
            <el-col>
              <el-form-item
                label="公司名称"
              >
                <span>{{form.companyName}}</span>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="公司介绍"
              >
                <div class="has-height">{{form.companyIntro}}</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="公司官网"
              >
                <div class="has-height">{{form.companyWebsite}}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="营业执照"
              >
                <img
                  class="img-ele"
                  @click="imgHandel(form.companyLisence)"
                  :src="form.companyLisence"
                  alt=""
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="联系地址"
              >
                <span>{{form.contactAddress}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系人姓名"
              >
                <span>{{form.contactName}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="联系人电话"
              >
                <span>{{form.contactPhone}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人邮箱">
                <span>{{form.contactEmail}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="certificate-upload"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  class=""
                  label="联系人身份证照片"
                >
                  <img
                    class="img-ele"
                    @click="imgHandel(form.contactIdPic)"
                    :src="form.contactIdPic"
                    alt=""
                  >
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="联系人身份证照片"
                  class=""
                >
                  <img
                    class="img-ele"
                    @click="imgHandel(form.contactIdPicBack)"
                    :src="form.contactIdPicBack"
                    alt=""
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="背景信息" name="second">
        <el-form
          ref="form"
          :model="formBackground"
          label-width="auto"
          label-position="left"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="外汇/金融行业从业时间">
                <!--返回数据也要number-->
                <span v-if="formBackground.employmentTime == 1">0-2年</span>
                <span v-if="formBackground.employmentTime == 2">2-5年</span>
                <span v-if="formBackground.employmentTime == 3">5-10年</span>
                <span v-if="formBackground.employmentTime == 4">10年以上</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="曾经合作过的经纪商">
                <span>{{formBackground.coopBrokerUsed}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="isATeam" label="是否有团队（是，人数多少）">
                <span>{{formBackground.isATeam}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最高峰名下客户量（人数）">
                <span>{{formBackground.custAmountMax}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="最高峰客户资金量">
                <span>{{formBackground.financingVolumeMax}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="现有客户量（人数）" >
                <span>{{formBackground.custAmountNow}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item  label="现有客户资金量（美元）">
                <span>{{formBackground.financingVolumeNow}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="现在的业务营销模式">
                <span>{{formBackground.bussMarketingMode}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="计划的社区营销模式">
                <span>{{formBackground.commMarketingMode}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="3个月预计客户量（人数）">
                <span>{{formBackground.custAmountExpect}}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="3个月预计客户资金量（美元）">
            <span>{{formBackground.financingVolumeExpect}}</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="建站资料" name="three">
        <el-form
          ref="form"
          :model="formSite"
          label-width="140px"
          label-position="rihgt"
        >
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="公司名称">
                <span>{{formSite.companyName}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="品牌名称">
                <span>{{formSite.brandName}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="productInfo.productId == 1">
            <el-row :gutter="40">
              <el-col>
                <el-form-item label="公司介绍">
                  <div class="has-height">{{formSite.companyIntro}}</div>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="团队介绍">
                  <div class="has-height">{{formSite.teamIntro}}</div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="宣传标语（Slogan）">
                  <span>{{formSite.companySlogan}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="愿景">
                  <span>{{formSite.expectation}}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="目标">
                  <span>{{formSite.goal}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="使命">
                  <span>{{formSite.mission}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="域名">
                <span>{{formSite.domainName}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>

          <template v-for="(item, index) in agentInfos">
            <div v-if="item.platformId">
              <el-row :gutter="40">
                <el-col :span="8">
                  <el-form-item label="代理平台">
                    <span>{{item.platformId == 1 ? 'Redstone' : 'UGL'}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="代理人姓名">
                    <span>{{item.agentName}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="代理号">
                    <span>{{item.agentNum}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-form-item label="直客链接">
                    <span>{{item.directLink}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="代理链接">
                    <span>{{item.agentLink}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </template>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="工作时间">
                <span>{{formSite.workTime}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="在线客服QQ">
                <span>{{formSite.qqCustService}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="客服电话">
                <span>{{formSite.custServicePhone}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客服邮箱">
                <span>{{formSite.custServiceEmail}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item  class="border-bot" label="导航栏LOGO" >
                <img
                  v-if="formSite.navibarLogo"
                  class="siteinfo-img img-ele"
                  :src="formSite.navibarLogo"
                  @click="imgHandel(formSite.navibarLogo)"
                  alt="">
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="productInfo.productId == 1">
              <el-form-item class="border-bot" label="底部栏LOGO" >
                <img
                  v-if="formSite.footbarLogo"
                  class="siteinfo-img img-ele"
                  :src="formSite.footbarLogo"
                  @click="imgHandel(formSite.footbarLogo)"
                  alt="">
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item  class="border-bot" label="网站icon" >
                <img
                  v-if="formSite.icon"
                  class="siteinfo-img img-ele"
                  :src="formSite.icon"
                  @click="imgHandel(formSite.icon)"
                  alt="">
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  class="border-bot" label="微信公众号二维码" >
                <img
                  v-if="formSite.weixinQcodePub"
                  class="siteinfo-img img-ele"
                  :src="formSite.weixinQcodePub"
                  @click="imgHandel(formSite.weixinQcodePub)"
                  alt="">
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            v-if="productInfo.productId == 1"
            :gutter="30">
            <el-form-item  class="border-bot" label="客服微信二维码" >
              <img
                v-if="formSite.weixinQcodeCustService"
                class="siteinfo-img img-ele"
                :src="formSite.weixinQcodeCustService"
                @click="imgHandel(formSite.weixinQcodeCustService)"
                alt="">
            </el-form-item>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div class="apply-content">
    </div>
    <div class="line" v-if="dialogTitle != 'look'">
      <span class="audit-result-text">审核结果</span>
      <el-radio-group v-model="auditResult">
<!--        status的状态有变化-->
        <el-radio
          v-if="productInfo.status == 2"
          label="b"
        >审核通过</el-radio>
        <el-radio
          v-if="productInfo.status == 2"
          label="a"
        >退回补件</el-radio>
        <el-radio
          v-if="productInfo.status == 2"
          label="c"
        >拒绝</el-radio>
        <el-radio
          v-if="productInfo.status == 3"
          label="d"
        >建站完成</el-radio>
      </el-radio-group>
    </div>
    <template v-if="dialogTitle != 'look'">
      <el-row class="remark-row" v-if="productInfo.status == 2">
        <el-col :span="12">
          <span class="remark-row-text">审核意见</span>
          <el-input
            type="textarea"
            row="4"
            resize="none"
            v-model="auditComment"
            placeholder="备注信息"
          ></el-input>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
      </div>
    </template>
    <el-dialog
      class="preview-img"
      :visible.sync="previewFlag"
      append-to-body
      width="80%"
    >
      <div class="img-outer">
        <img class="preview-img-ele" :src="currentImg" alt="">
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { doAudit } from '@/api/alitatech/apply.js'
export default {
  props: {
    dialogShow:{
      type: Boolean,
      default: false
    },
    productInfo:{
      type: Object,
      required: true,
    },
    dialogTitle: {
      // 'look' or do
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      previewFlag: false,
      currentImg: '',
      auditResult: 'b', // 审核结果
      activeName: 'first',
      dialog: false,
      auditComment: '', // 备注
      form:{
        companyName: '',
        companyIntro: '',
        companyWebsite: '',
        companyLisence: '',
        contactAddress: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',

        contactIdPic: '',
        contactIdPicBack:'',
      },
      formBackground:{
        employmentTime: 1,
        coopBrokerUsed:'',
        isATeam: '',
        custAmountMax: '',
        financingVolumeMax: '',
        custAmountNow: '',
        financingVolumeNow: '',
        bussMarketingMode: '',
        commMarketingMode: '',
        custAmountExpect: '',
        financingVolumeExpect: '',
      },
      formSite:{
        companyName: '',
        brandName: '',
        companyIntro: '',
        teamIntro: '',
        companySlogan: '',
        expectation: '',
        goal: '',
        mission: '',
        domainName: '',
        qqCustService: '',
        workTime: '',
        custServicePhone: '',
        custServiceEmail : '',
        navibarLogo : '',
        footbarLogo : '',
        icon : '',
        weixinQcodePub : '',
        weixinQcodeCustService : '',
      },
      agentInfos:[
        {
          platformId: 0,
          agentName: '',
          agentNum: '',
          directLink: '',
          agentLink: '',
        }
      ]
    }
  },
  methods: {
    imgHandel(imgUrl){
      this.previewFlag = true;
      this.currentImg = imgUrl;
    },
    cancel(){
      this.$emit('close-dialog');
    },
    handleClose(){
      this.$emit('close-dialog')
    },
    doSubmit(str) {
      // let auditContent = str;
      let  params = {
          registerPhone: this.productInfo.registerPhone,
          productId: this.productInfo.productId,
          status: 2,
          auditContent: this.auditResult,
          auditComment: this.auditComment,
        }
        if(this.auditResult == 'd'){
          delete params.auditComment;
          params.status = 4;
        }
      doAudit(params).then((res) => {
        this.$emit('refush-data',{
          productId: this.productInfo.productId,
          userId: this.productInfo.userId,
        });
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        sectorCode: '',
        userName: '',
        fullname: '',
        password: '',
        phone: '',
        email: '',
        managerId: '',
        parentMerchantId: '',
        merchantType: 1
      };
      this.confirmpassword = '';
    },
    handleClick(){

    }
  }
}
</script>
<style lang="less">
  .apply-detail .el-scrollbar__wrap {
    overflow-x: hidden;
  }

</style>
<style scoped lang="less">
  .audit-result-text {
    margin-right: 20px;
  }
  .remark-row-text {
    display: block;
    margin: 10px 0;
  }
.apply-detail {
  /*.apply-content {*/
    /*height: 600px;*/
  /*}*/
  .img-ele {
    max-width: 200px;
    max-height: 200px;
    cursor: zoom-in;
  }
  .remark-row {
    margin: 20px 0;
  }
  .siteinfo-img {
    max-width: 200px;
    max-height: 200px;
  }
}
  .dialog-footer {
    text-align: center;
  }
  .has-height {
    max-height: 120px;
    overflow-y: auto;
  }
  .preview-img {
    .img-outer {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
      .preview-img-ele {
        width: 100%;
      }
    }
  }
</style>

import request from '@/utils/request'

/*
String registerPhone，
int status
可传参数查询
get*/
export function getAlitaList(data) {
  return request({
    url: '/portal/crm/getStatusDetail',
    method: 'get',
    params: data
  })
}


// registerPhone
// productId
//
// 手机号和产品id
export function getAlitaDetail(data) {
  return request({
    url: '/user/crm/getStatusOne',
    method: 'get',
    params: data
  })
}

//   registerPhone 手机号
// productId 产品Id
// status 状态
// auditContent “a”不通过，"b" 审核通过 , "c" 审核决绝
// auditComment  审批的批注或者结论
//
// 传入status = 2  auditContent = "a"  把资料返回
// 传入status = 2  auditContent = "b"  审核通过
// 传入status = 2  auditContent = "c"  审核不通过
//
// 传入status=4  建站完成

export function doAudit(data) {
  return request({
    url: '/user/crm/audit',
    method: 'POST',
    data: data
  })
}

export function srSiteBuild(data) {
  return request({
    url: '/user/crm/siteBuild',
    method: 'POST',
    data: data
  })
}

export function copyAndReplace(data) {
  return request({
    url: '/user/crm/siteCopyAndReplace',
    method: 'POST',
    data: data
  })
}



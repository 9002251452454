<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input
      v-model="query.id"
      clearable
      placeholder="客户ID"
      class="filter-item"
      style="width: 100px;"
      @keyup.enter.native="toQuery"
      @clear="toQuery"
    />
    <el-input
      v-model="query.phone"
      clearable
      placeholder="客户电话/客户名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
      @clear="toQuery"
    />
    <el-input
      v-model="query.manageName"
      clearable
      placeholder="客户经理名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
      @clear="toQuery"
    />
    <el-select
      v-if="showStatus"
      v-model="query.status"
      clearable placeholder="请选择状态"
      class="filter-item"
               style="width: 150px"
    >

      <el-option
        v-for="item in siteStatuss"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <div class="filter-item">
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-input
      v-model="query.brandName"
      clearable
      placeholder="品牌名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
      @clear="toQuery"
    />
    <el-select
      v-model="query.productType"
      clearable placeholder="请选择产品类型"
      class="filter-item"
      style="width: 150px"
    >
      <el-option
        v-for="item in productTypeList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission' // 权限判断函数
  export default {
    props: {
      showStatus: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        query:{
          id: '',
          phone: '',
          manageName: '',
          status: 99,
          startTime: '',
          endTime: '',
          brandName: '',
          productType: '',
        },
        siteStatuss: [
          {
            label: '全部',
            value: 99
          },
          {
            label: '新建/未保存',
            value: 0,
          },
          {
            label: '未提交',
            value: 1,
          },
          {
            label: '待审核 ',
            value: 2,
          },
          {
            label: '审核通过',
            value: 3
          },
          {
            label: '搭建成功',
            value: 4
          },
          {
            label: '审核拒绝',
            value: 5
          },
          {
            label: '已过期',
            value: 6
          }
        ],
        productTypeList:[
          {
            label: '全部',
            value: 99,
          },
          {
            label: '综合社区',
            value: 2,
          },
          {
            label: '跟单版',
            value: 3,
          },
          {
            label: 'mam多账户',
            value: 5,
          },
        ]
      }
    },
    methods: {
      checkPermission,
      toQuery() {
        this.$emit('search-click', this.query);
      }
    }
  }
</script>

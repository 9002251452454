<template>
  <div class="app-container">
    <eHeader
      :show-status="showStatus"
      @search-click="searchHandle"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
    >
      <el-table-column prop="userId" label="客户ID"/>
      <el-table-column prop="userName" label="客户名称"/>
      <el-table-column prop="phone" label="客户电话">
      </el-table-column>
      <el-table-column prop="registerPhone" label="产品类型">
        <template slot-scope="scope">
          <span>{{getProductName(scope.row.siteType)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="品牌名称/英文">
        <template slot-scope="scope">
          <span>{{scope.row.brandName}}</span>
          <br>
          <span>{{scope.row.domainName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品版本">
        <template slot-scope="scope">
            <span>{{getFree(scope.row.version)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品有效期">
        <template slot-scope="scope">
          <span>{{ getDuration(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ getSiteStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.applyTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="managerName" label="客户经理">
        <template slot-scope="scope">
          <p style="white-space: pre;">{{scope.row.managerName}}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            v-if="checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_SELECT'])"
            @click.native="doHandle(scope.row, 'look')"
          >查看</el-button>
          <el-button
            v-if="(dataStatus == 2 || dataStatus == 3)&&checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_CREATE'])"
            size="mini"
            type="danger"
            @click.native="doHandle(scope.row, 'audit')"
          >审核</el-button>
           <el-popover
            v-if="dataStatus == 3&&checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_COPY'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定执行建站操作吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="srSiteBuild(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="warning" size="mini">建站</el-button>
          </el-popover>
          <el-popover
            v-if="dataStatus == 3&&checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_COPY'])"
            :ref="scope.row.id +'copy'"
            placement="top"
            width="180">
            <p>确定执行替换操作吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id +'copy'].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="copyAndReplace(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="primary" size="mini">替换</el-button>
          </el-popover>

          </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
      />
    <detai-dialog
      ref="detaidialog"
      :dialog-title="dialogTitle"
      :dialog-show="dialogShow"
      :product-info="productInfo"
        @close-dialog="closeHandel"
      @refush-data="refushData"
    ></detai-dialog>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from './header'
import edit from './edit'
import { getAlitaList, getAlitaDetail,srSiteBuild ,copyAndReplace} from '@/api/alitatech/apply.js'
import detaiDialog from './form'
export default {
  name: 'viewTable',
  props:{
    showStatus:{
      type: Boolean,
      default: false
    },
    dataStatus:{
      rquired: true,
    },
    reFresh:{
      type: Boolean,
      default: false,
    }
  },
  components: {
    eHeader,
    edit,
    detaiDialog
  },
  data() {
    return {
      currentPage: 0,
      size: 10,
      loading: false,
      delLoading: false,
      dialogShow: false,
      data: [],
      total: 1,
      listQuery: {
        phone: '',
        status: ''
      },
      dialogTitle: 'look',
      productInfo: {
        registerPhone: '',
        productId: '',
        status: '',
        userId: '',
      }
    }
  },
  watch:{
    reFresh(){
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    parseTime,
    checkPermission,
    getProductName(type){
      switch (type) {
        case 2:
          return '综合社区';
        case 3:
          return '跟单版';
        case 4:
          return '跨平台API服务';
        case 5:
          return 'mam多账户';
        case 6:
          return '客户管理系统CRM';
      }
    },
    getFree(version){
      return version == 0 ? '免费': '收费';
    },
    getDuration(row){
      if(!row.beginTime){
        return '-';
      }
      return this.parseTime(row.beginTime).split(' ')[0] + ' 至 ' +  this.parseTime(row.endTime).split(' ')[0];
    },
    getSiteStatus(status){
      switch (status) {
        case 0:
          return '新建/未保存';
        case 1:
          return '未提交';
        case 2:
          return '待审核';
        case 3:
          return '审核通过';
        case 4:
          return '搭建成功';
        case 5:
          return '审核拒绝';
        case 6:
          return '过期';
      }
    },
    copyAndReplace(row){
      this.delLoading = true
      let params={id:row.id}
      copyAndReplace(params).then((res) => {
         this.$notify({
          title: '替换成功',
          type: 'success',
          duration: 2500
        })
        this.delLoading = false;
        this.$refs[row.id+'copy'].doClose()
      }).catch(err => {
        this.delLoading = false
        this.$refs[row.id+'copy'].doClose()
        console.log(err.response.data.message)
      })
    },
    srSiteBuild(row){
      this.delLoading = true
      let params={id:row.id}
      srSiteBuild(params).then((res) => {
         this.$notify({
          title: '建站成功',
          type: 'success',
          duration: 2500
        })
        this.delLoading = false;
        this.$refs[row.id].doClose()
      }).catch(err => {
        this.detaiDialog = false
        this.$refs[row.id].doClose()
      })
    },
    doHandle(row, title){
      // row 传递数据给dialog
      this.dialogTitle = title;
      getAlitaDetail({
        productId: row.productId,
        userId: row.userId,
      }).then((res) => {
        console.log(res);
        let content = res;
        if(content.result.backgroundInfo){
          this.$refs.detaidialog.formBackground = content.result.backgroundInfo;
        }
        if(content.result.companyInfo){
          this.$refs.detaidialog.form= content.result.companyInfo;
        }
        if(content.result.siteBuildInfo){
          this.$refs.detaidialog.formSite= content.result.siteBuildInfo;
        }
        if(content.result.agentInfos){
          this.$refs.detaidialog.agentInfos= content.result.agentInfos;
        }
        this.dialogTitle = title;
        this.productInfo.registerPhone = row.phone;
        this.productInfo.userId = row.userId;
        this.productInfo.productId = row.productId;
        this.productInfo.status = row.status;
        this.dialogShow = true;
      });
    },
    refushData(item){
      this.dialogShow = false;
      getAlitaDetail({
        productId: item.productId,
        userId: item.userId,
      }).then((res) => {
        this.productInfo.status = res.statusDetail.status;
      });
      this.getList();
    },
    closeHandel(){
      this.dialogShow = false;
    },
    getStatus(item){
      let text = ''
      switch (item.status) {
        case 0:
          text = '待修改'
          break;
        case 1:
          text = '待修改'
          break;
        case 2:
          text = '待审核'
          break;
        case 3:
          text = '待搭建'
          break;
        case 4:
          text = '搭建完成'
          break;
        case 5:
          text = '审核拒绝 '
          break;
      }
      return text;
    },
    searchHandle(query){
      this.listQuery = query;
      this.currentPage = 0;
      this.getList();
    },
    getList(){
      let params = {
        page: this.currentPage,
        size: this.size,
        userId: this.listQuery.id,
        userInfo: this.listQuery.phone,
        status: this.dataStatus,
        managerName: this.listQuery.manageName,
        beginTime: this.listQuery.startTime,
        endTime: this.listQuery.endTime,
        brandInfo: this.listQuery.brandName,
        siteType: this.listQuery.productType,
      };
      if(this.dataStatus == 99){
        params.status = this.listQuery.status;
      }
      getAlitaList(params).then((res) => {
        this.data = res.content;
        this.total = res.totalElement;
      })
    },
    pageChange(e) {
      this.currentPage = e - 1
      this.getList();
    },
    sizeChange(e) {
      this.currentPage = 0;
      this.size = e;
      this.getList();
    }
  }
}
</script>

<style scoped>

</style>
